import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axiosConfig from "../Service/axiosConfig";
import { Footer, Navbar, PopupModal } from "../Components";
import CustomTop from "./CustomTop";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal } from "react-bootstrap";
import { FormParser } from '../Components/FormParser/FormParser';
import Masters from "../Components/masters.json";
import { AuditionContent } from "./AuditionContent";
import { useAuth } from '../Utils/AuthContext';

const AuditionPage = () => {
    const { isLoggedIn, is24Craft } = useAuth();
    const profile_data = JSON.parse(localStorage.getItem('profile_data'));
    const frames_24 = localStorage.getItem('frames_24');
    const [searchParams, setSearchParams] = useSearchParams();
    const [popupType, setpopupType] = useState("");
    const [Error, SetError] = useState("");
    const [loading, setLoading] = useState(false);
    const [AudiData, setAudiData] = useState([]);
    const [filterShow, setFilterShow] = useState(false);
    const [params, setParams] = useState({
        status: searchParams.get("status") || "active",
        profile: searchParams.get("profile") || ''
    });
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1020);
    const [totalPages, settotalPages] = useState();
    const [loading2, setLoading2] = useState("");
    const handleResize = () => {
        setIsMobile(window.innerWidth < 1020);
    };
    let page = 1;
    const Navigate = useNavigate();
    const handleNavigate = (id) => {
        Navigate(`/auditions/add`)
    }
    const formSubmit = (Data) => {
        setFilterShow(false)
        let queryStringParts = [];
        console.log({ ...params, ...Data })
        const datatosend = {
            ...params, ...Data
        }
        Object.keys(datatosend).forEach(key => {
            const value = datatosend[key];
            if (Array.isArray(value)) {
                value.forEach(arrayValue => {
                    queryStringParts.push(`${encodeURIComponent(key)}=${encodeURIComponent(arrayValue)}`);
                });
            } else {
                queryStringParts.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
            }
        });
        const queryString = queryStringParts.join('&');
        setSearchParams(queryString);
        setParams(datatosend)
        console.log(queryString);
        axiosConfig.get(`/accounts/auditions/?page_size=20&page=${page}&${queryString}`)
            .then(response => {
                settotalPages(Math.ceil(response.data.count / 20));
                setAudiData(response.data.results);
                setLoading(false);
            })
            .catch(error => {
                console.log(error.response.data.error);
            });
    };
    const GetData = async (status, profile) => {
        setLoading(true);
        try {
            const response = await axiosConfig.get(`/accounts/auditions/?status=${status}&profile=${profile}`);
            const current_path = window.location.pathname;
            settotalPages(Math.ceil(response.data.count / 20));
            setAudiData(response.data.results);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }
    const handleInfiniteScroll = async () => {
        try {
            if (
                window.innerHeight + document.documentElement.scrollTop + 1 >=
                document.documentElement.scrollHeight
            ) {
                page = page + 1;
                console.log(page);
                if (page <= totalPages && !loading2) {
                    setLoading2(true);
                    const resp = await axiosConfig.get(`/accounts/auditions/?page=${page}&status=${params.status}&profile=${params.profile}`);
                    setAudiData((Data) => [...Data, ...resp.data.results]);
                    setLoading2(false);
                }
            }
        } catch (error) {
            console.log(error);
            setLoading2(false);
        }
    };
    const handleStatusChange = (newStatus) => {
        const newParams = { ...params, status: newStatus };
        setParams(newParams);
        setSearchParams(newParams);
        GetData(newStatus, newParams.profile);
    };
    const handleProfileChange = (profile) => {
        const newParams = { ...params, profile };
        setParams(newParams);
        setSearchParams(newParams);
        GetData(newParams.status, profile);
    };
    useEffect(() => {
        GetData(params.status, params.profile);
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', handleInfiniteScroll);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('scroll', handleInfiniteScroll);
            window.removeEventListener("resize", handleResize);
        }
    }, [totalPages]);

    return (
        <>
            <CustomTop />
            <div className="search-screen-container">
                <Navbar />
                <div className="content-area">
                    <div style={{ marginTop: "50px" }} className='main-cards'>
                        <div className="opportunities">
                            <div className="row justify-content-between">
                                <div className="col-md-7">
                                    <h6 className="opp-title">Opportunities</h6>
                                    <p className="opp-quote">Continue your star journey</p>
                                </div>
                                <div className="col-md-5">
                                    <div className="btnflx">
                                        {isLoggedIn &&
                                            <div className="auditons-status-btns">
                                                <button className={`btn ${params.profile === "" ? "btn-secondary" : "btn-info"}`} onClick={() => handleProfileChange('')}>All Auditions</button>
                                                <button className={`btn ${params.profile === profile_data?.profile_id ? "btn-secondary" : "btn-info"}`} onClick={() => handleProfileChange(profile_data.profile_id)}>My Auditions</button>
                                            </div>
                                        }
                                        {frames_24?.split(',')?.some(role =>
                                            ["Executive Producer", "Casting Director", "Producer", "Director", "Associate Director", "Co-Director", "Assistant Director"].includes(role)
                                        ) && isLoggedIn && (
                                                <div className="btnitem">
                                                    <button className="btn btn-primary" onClick={handleNavigate}>+Add New</button>
                                                </div>
                                            )}
                                    </div>
                                </div>
                            </div>
                            <div className="Audi-status">
                                <div className="auditons-status-btns">
                                    <button className={`btn ${params.status === "active" ? "btn-secondary" : "btn-info"}`} onClick={() => handleStatusChange("active", "")}>Active</button>
                                    <button className={`btn ${params.status === "completed" ? "btn-secondary" : "btn-info"}`} onClick={() => handleStatusChange("completed", "")}>Past Auditions</button>
                                </div>
                                <div className="audi-filter">
                                    <button className="btn btn-secondary" onClick={() => setFilterShow(true)}>Filter by</button>
                                </div>
                            </div>
                        </div>
                        <div className="opportunity-card" style={{ marginTop: '50px' }}>
                            <div className="row">
                                <AuditionContent AudiData={AudiData} />
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <Footer />
            {
                popupType !== "" &&
                <PopupModal popupType={popupType} setpopupType={setpopupType} error={Error} />
            }
            <Modal
                size="lg"
                show={filterShow}
                onHide={() => setFilterShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Filters
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormParser formData={params} modelObject={Masters.AuditionFilter} formSubmit={formSubmit} error={Error} />
                </Modal.Body>
            </Modal>
        </>
    );
}

export { AuditionPage };
