import React, { useEffect, useState } from 'react';
import axiosConfig from "../../Service/axiosConfig"
import Modal from 'react-bootstrap/Modal';
import { NavLink } from "react-router-dom";
import { Follow } from './Follow';

const FollowingPop = ({ ProfileData, Following, setFollowing }) => {
    console.log(ProfileData, "ProfileData")
    const [Likes, setLikes] = useState([]);
    const GetLikes = async () => {
        try {
            console.log(ProfileData.user.id)
            const response = await axiosConfig.get(`/accounts/follows/?user=${ProfileData.user.id}&page_size=100`)
            console.log(response)
            setLikes(response.data.results)
        }
        catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        GetLikes();
    }, [])
    return (
        <>
           <div className='mobile-view-following'>
           {Likes.map((like) => {
                        return (
                            <>
                                <div className='like-content'>
                                    <div className='d-flex' style={{ gap: '10px' }}>
                                        <NavLink target="_blank" to={`/profile/${like.profile.hcc_id}`}>
                                            <img
                                                src={like.profile.profile_pic ? like.profile.profile_pic : require('../Post/images/male.jpg')}
                                                alt='' className='profile-like'
                                            />
                                        </NavLink>
                                        <div>
                                            <NavLink target="_blank" to={`/profile/${like.profile.hcc_id}`} className="like-name">
                                                <span>{like.profile ? `${like.profile.first_name}` : ''}</span>
                                            </NavLink>
                                        </div>
                                    </div>
                                    <Follow FollowId={like.profile.id} />
                                </div>
                            </>
                        )
                    })}
           </div>
            < Modal
                show={Following}
                onHide={() => setFollowing(false)}
                aria-labelledby="example-modal-sizes-title-sm"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Following
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {Likes.map((like) => {
                        return (
                            <>
                                <div className='like-content'>
                                    <div className='d-flex' style={{ gap: '10px' }}>
                                        <NavLink target="_blank" to={`/profile/${like.profile.hcc_id}`}>
                                            <img
                                                src={like.profile.profile_pic ? like.profile.profile_pic : require('../Post/images/male.jpg')}
                                                alt='' className='profile-like'
                                            />
                                        </NavLink>
                                        <div>
                                            <NavLink target="_blank" to={`/profile/${like.profile.hcc_id}`} className="like-name">
                                                <span>{like.profile ? `${like.profile.first_name}` : ''}</span>
                                            </NavLink>
                                        </div>
                                    </div>
                                    <Follow FollowId={like.profile.id} />
                                </div>
                            </>
                        )
                    })}
                </Modal.Body>
            </Modal >
        </>
    );
};

export { FollowingPop };
