import React, { useState, useEffect } from "react";
import axiosConfig from "../Service/axiosConfig"
import { Footer, Navbar, DeletePopupModal } from "../Components";
import { useNavigate, useSearchParams, NavLink, useParams } from "react-router-dom";
import CustomTop from "./CustomTop";
import { AlertDeletePopupModal } from "../Components/PopupModal/AlertDeletePopupModal"
import { MdMoreVert, MdOutlineArrowBackIos, MdOutlineLocalPhone, MdOutlineRemoveRedEye, MdOutlineTouchApp } from "react-icons/md";
import { LuSendHorizonal } from "react-icons/lu";
import { Card, Row, Col } from 'react-bootstrap';
import { AuditionApplications } from "./AuditionApplications";
import { Modal, Button } from 'react-bootstrap';
const AuditionDetailPage = ({ }) => {
    let { id } = useParams()
    const [loading, setLoading] = useState(false)
    const [Data, SetData] = useState([])
    const [requirementData, setRequirementData] = useState([])
    const [Applications, setapplications] = useState(false)
    const [AudiData, setAudiData] = useState([])
    const [RequireId, setRequireId] = useState(null)
    const [show, setShow] = useState(false);

    const handleSmallClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const GetData = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`/accounts/auditions/${id}/`)
            console.log(responce.data)
            AuditionView()
            setAudiData(responce.data)
            setLoading(false)
        }
        catch (error) {
            console.log(error)
            setLoading(false)
        }
    }
    const handlShow = (id) => {
        setapplications(true)
        setRequireId(id)
    }
    const handleClose = () => {
        setapplications(false)
        setRequireId(null)
    }
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("")
    const [deletePopupType, setDeletepopupType] = useState("")
    const [RowId, SetRowId] = useState()
    const handleLoginShow = () => {
        document.querySelector('.login-text').click();
    }
    const DeleteAlert = (id) => {
        if (localStorage.getItem('token')) {
            SetRowId(id)
            SetAlertDeletePopupType("success")
        } else {
            handleLoginShow();
        }
    }
    const CancelAlerrt = () => {
        SetAlertDeletePopupType("error")
    }
    const handleApply = (id) => {
        console.log(id)
        axiosConfig.post("/accounts/audition-applications/", { audition_requirement: id }).then(res => {
            console.log(res)
            setDeletepopupType("success")
            SetAlertDeletePopupType("error")
        }).catch(error => {
            console.log(error)
        })
    }
    function getProjectTypeColor(status) {
        switch (status) {
            case 'Movies':
                return '#8b008b';
            case 'OTT':
                return '#696969';
            case 'TV_Serials':
                return '#8b4513';
            case 'Web_Series':
                return '#4682b4';
            case 'Music_Albums':
                return '#bdb76b';
            case 'Cover_Songs':
                return '#a52a2a';
            case 'Item_Song':
                return '#c71585';
            case 'Short_Films':
                return '#228b22';
            case 'Ads':
                return '#d2691e';
            case 'Modelling':
                return '#20b2aa';
            case 'Audition':
                return '#db7093';
            default:
                return '#ffffff';
        }
    }
    function getProjectType(status) {
        console.log(status)
        switch (status) {
            case 'Movies':
                return '#f5d8ff';
            case 'OTT':
                return '#c1c1cc';
            case 'TV_Serials':
                return '#ffd8b1';
            case 'Web_Series':
                return '#b3ecff';
            case 'Music_Albums':
                return '#f0e68c';
            case 'Cover_Songs':
                return '#ffcccb';
            case 'Item_Song':
                return '#ff69b4';
            case 'Short_Films':
                return '#b2fab4';
            case 'Ads':
                return '#ffdab9';
            case 'Modelling':
                return '#e0ffff';
            case 'Audition':
                return '#ffb6c1';
            default:
                return '#ffffff';
        }
    }
    const AuditionView = async () => {
        try {
            const responce = await axiosConfig.get(`/accounts/auditions/${id}/viewed/`)
            console.log(responce)
        }
        catch (error) {
            console.log(error);
        }
    }
    const Navigate = useNavigate();
    const handleNavigate = (link, type) => {
        console.log(link, type);
        if (type === "profile") {
            Navigate(`/profile/${link}`)
        }
        if (type === "back") {
            Navigate(`/auditions`)
        }
    }
    useEffect(() => {
        GetData()
        GetRequirementdata()
    }, [])
    const GetRequirementdata = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`/accounts/audition-requirements/?audition=${id}`)
            console.log(responce)
            setRequirementData(responce.data.results);
            setLoading(false);
        }
        catch (error) {
            console.log(error)
            setLoading(false);
        }
    }
    return (
        <>
            <CustomTop />
            <div className="search-screen-container">
                <Navbar />
                <div className="content-area">
                    <div className="investment-screen">
                        <div className="contents">
                            <div className="card left-panel bax-shadow">
                                <div style={{ backgroundColor: getProjectType(AudiData.project_type) }}>
                                    <div className="d-flex align-items-center justify-content-center">
                                        <div className="card-type" style={{ backgroundColor: getProjectTypeColor(AudiData.project_type) }}>{AudiData.project_type ? AudiData.project_type : "---"}</div>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center card-descrt-inner">
                                        <div onClick={() => handleNavigate("", "back")} className="back-button"><MdOutlineArrowBackIos size={20} /> Back</div>
                                        {AudiData.banner_name &&
                                            <>
                                                <h4 className="banner-title">
                                                    {AudiData.banner_name}
                                                </h4>
                                            </>
                                        }
                                        <div className="back-button"><MdMoreVert size={25} /></div>
                                    </div>
                                    {/* <hr /> */}
                                    <div className='card-content'>
                                        <div className="card-styles">
                                            <div className='card-content'>
                                                <div className="card-descrt">
                                                    <div className="card-descrt-inner">
                                                        {/* {AudiData.banner_name ? (
                                                        <>
                                                            <h4 className="banner-title">
                                                                {AudiData.banner_name}
                                                            </h4>
                                                            <hr />
                                                        </>
                                                    ) : (
                                                        <div style={{ padding: '20px' }}></div>
                                                    )} */}
                                                        <div style={{ color: "#000" }} className="project_title">
                                                            <div className="audi-id">AD{AudiData.id && AudiData.id}</div>
                                                            <div>
                                                                <div className='Audi-project-title'>{AudiData.project_title && AudiData.project_title}</div>
                                                                <div className='Audi-description'>{AudiData.generated_requirement && AudiData.generated_requirement}</div>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex justify-content-between">
                                                            {AudiData.director_name && <div className="card-botton-centent">
                                                                <div className="card-cont-title" style={{ fontSize: "14px" }}>DIRECTOR</div>
                                                                <div className='card-cont-detail' style={{ color: "#000", fontSize: '16px' }}>{AudiData.director_name}</div>
                                                            </div>}
                                                            {AudiData.producer_name && <div className="card-botton-centent">
                                                                <div className="card-cont-title" style={{ fontSize: "14px" }}>PRODUCER</div>
                                                                <div className='card-cont-detail' style={{ color: "#000", fontSize: '16px' }}>{AudiData.producer_name}</div>
                                                            </div>}
                                                            {AudiData.hero_name && <div className="card-botton-centent">
                                                                <div className="card-cont-title" style={{ fontSize: "14px" }}>HERO</div>
                                                                <div className='card-cont-detail' style={{ color: "#000", fontSize: '16px' }}>{AudiData.hero_name}</div>
                                                            </div>}
                                                        </div>
                                                        <div className="card-detail">
                                                            <div className="card-botton-centent">
                                                                <div className="card-cont-title">Zoner</div>
                                                                <div className='card-cont-detail'>{AudiData.zoner ? AudiData.zoner : "-"}</div>
                                                            </div>
                                                            {AudiData.platform && <div className="card-botton-centent">
                                                                <div className="card-cont-title">Platform</div>
                                                                <div className='card-cont-detail'>{AudiData.platform}</div>
                                                            </div>}
                                                            {AudiData.loc_lang?.state && AudiData.loc_lang?.city && (
                                                                <div className="card-botton-centent">
                                                                    <div className="card-cont-title">Location</div>
                                                                    <div className='card-cont-detail'>
                                                                        {AudiData.loc_lang.city}({AudiData.loc_lang.state})
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="auditionLists">
                                                            <div style={{ display: "flex", flexDirection: 'column' }}>
                                                                <div className="card-cont-title">Description</div>
                                                                <div className='card-cont-detail'>
                                                                    {AudiData.description && AudiData.description.trim() !== "" ? AudiData.description : "No requirements available"}
                                                                </div>
                                                            </div>
                                                            <div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-bottom">
                                                        <div className="card-bottom-inner">
                                                            <div className="card-bottom-left card-botton-centent">
                                                                <div className="card-cont-title">Posted On</div>
                                                                <div className='card-cont-detail'>
                                                                    {AudiData.start_date
                                                                        ? new Date(AudiData.start_date).toLocaleDateString('en-GB').split('/').join("-")
                                                                        : "-"}
                                                                </div>
                                                                {/* <div className='card-cont-detail'>{AudiData.start_date ? AudiData.start_date : "-"}</div> */}
                                                            </div>
                                                            <div className="card-bottom-right-details card-botton-centent">
                                                                <div className="card-cont-title">Closes On</div>
                                                                <div className='card-cont-detail'>
                                                                    {AudiData.end_date
                                                                        ? new Date(AudiData.end_date).toLocaleDateString('en-GB').split('/').join("-")
                                                                        : "-"}
                                                                </div>
                                                                {/* <div className='card-cont-detail'>{AudiData.end_date ? AudiData.end_date : "-"}</div> */}
                                                                {/* <div className="card-cont-title">Posted By</div>
                                                            {AudiData.profile?.first_name && <div className='card-cont-detail' onClick={() => handleNavigate(AudiData.profile?.hcc_id, "profile")}>
                                                                {AudiData.profile.first_name}
                                                            </div>} */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div >
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="right-panel">
                                <div className="investment-box bax-shadow">
                                    <div>
                                        <label>{AudiData.project_type} Budget</label>
                                        <input type="number" value={AudiData.budget ? AudiData.budget : 0}
                                            disabled />
                                    </div>
                                    <div>
                                        {/* <div className="card-botton-centent">
                                            <div className="card-cont-title">Closes On</div>
                                            <div className='card-cont-detail'>{AudiData.end_date ? AudiData.end_date : "-"}</div>
                                        </div> */}
                                        <div className="card-actions">
                                            <div className="card-botton-centent">
                                                <div className="card-cont-title">Applications</div>
                                                <div className='card-cont-detail'>{AudiData.applications ? AudiData.applications : "-"}</div>
                                            </div>
                                            <div className="card-botton-centent">
                                                <div className="card-cont-title">Views</div>
                                                <div className='card-cont-detail'>{AudiData.views}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        {/* <div className="card-cont-title">Contact Info:</div>
                                        {AudiData.contact_name && <div className='card-cont-detail'>{AudiData.contact_name}({AudiData.contact_designation})</div>} */}
                                        <div className="d-flex justify-content-between">
                                            <button className="btn btn-secondary w-100" onClick={handleShow}>
                                                <a>
                                                    {/* <MdOutlineLocalPhone size={20} /> */}
                                                    Contact Details</a>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="investment-screen">
                        <Row>
                            {requirementData.map((requirement, index) => (
                                <Col xl={4} lg={4} md={6} sm={12} xs={12} key={index}>
                                    <Card className="bax-shadow">
                                        <Card.Body>
                                            <Card.Title className="card-title-custom">Role - {requirement.character}</Card.Title>
                                            <Card.Text>
                                                <div className="applictn-dtls">
                                                    <div className="apply-content">
                                                        Gender: <span className="apply-data"> {requirement.gender}</span >
                                                    </div>
                                                    <div className="apply-content">
                                                        Age: <span className="apply-data"> {requirement.from_age} - {requirement.to_age} years</span>
                                                    </div>
                                                    <div className="apply-content">
                                                        Height: <span className="apply-data"> ft</span>
                                                    </div>
                                                    <div className="apply-content">
                                                        No. of Positions: <span className="apply-data"> {requirement.no_of_requirements}</span>
                                                    </div>
                                                    <div className="apply-content">
                                                        Languages: <span className="apply-data"> {requirement?.loc_lang?.languages}</span>
                                                    </div>
                                                    <div className="apply-content">
                                                        Preffered Location: <span className="apply-data"> {requirement?.loc_lang?.state}</span>
                                                    </div>
                                                    <div className="apply-content">
                                                        Specification: <span className="apply-data"> {requirement.specification}</span>
                                                    </div>
                                                </div>
                                                {/* <div className="row">
                                                    <div className="col-6 col-md-4"><strong>Specification:</strong></div>
                                                    <div className="col-6 col-md-8">{requirement.specification}</div>
                                                </div> */}
                                            </Card.Text>
                                            <Card.Footer className="text-center" style={{ background: "none", borderTop: "none", paddingLeft: '0px' }}>
                                                {AudiData?.profile?.id === JSON.parse(localStorage.getItem('profile_data'))?.profile_id ? (
                                                    <button type="button" className="btn btn-primary w-100" onClick={() => handlShow(requirement.id)}>View Applications</button>
                                                ) : (
                                                    <button type="button" className="btn btn-primary w-100" onClick={() => DeleteAlert(requirement.id)}><MdOutlineTouchApp /> Apply</button>
                                                )}
                                            </Card.Footer>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </div>
            </div >
            <Footer />
            <Modal show={show} onHide={handleSmallClose}
                size="sm"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Contact Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className="contact-dtls">
                            <div className="card-cont-detail">Name:</div>
                            {AudiData.contact_name && <div className='card-cont-title'>{AudiData.contact_name}</div>}
                        </div>
                        <div className="contact-dtls">
                            <div className="card-cont-detail">Designation:</div>
                            {AudiData.contact_name && <div className='card-cont-title'>{AudiData.contact_designation}</div>}
                        </div>
                        <div className="contact-dtls">
                            <div className="card-cont-detail">Mobile No:</div>
                            {AudiData.contact_name && <div className='card-cont-title'>{AudiData?.contact_mobile_no}</div>}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary w-100" onClick={handleSmallClose}>
                        <a href={`tel:${AudiData?.contact_mobile_no}`}><MdOutlineLocalPhone size={20} /> Call Now</a>
                    </Button>
                </Modal.Footer>
            </Modal>
            {Applications &&
                <AuditionApplications Applications={Applications} setapplications={setapplications} handleClose={handleClose} requirement={RequireId} />
            }
            {
                deletePopupType !== "" &&
                <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} message={"Applied for Audition SuccessFully!"} />
            }
            {
                AlertDeletePopupType === "success" &&
                <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} SetAlertDeletePopupType={SetAlertDeletePopupType} HandleDelete={handleApply} CancelAlerrt={CancelAlerrt} row={RowId} message={"Are You Sure You Want Apply for this Audition ?"} />
            }
        </>
    )
}
export { AuditionDetailPage };