import React,{useState} from 'react'
import { Modal } from 'react-bootstrap'
import Plans from './Plans'

const UpgradePage = ({ UpgradeShow, setUpgradeShow,ScreenVisbile, setScreenVisbile, handleUpgradeClose }) => {
    const [BuyNowData,setbuyNowData]=useState({})
    const hnadlescreen=(Title,month_dis,year,discount,month)=>{
        setbuyNowData({Title:Title,month_disount:month_dis,year:year,discount:discount,month:month})
        setScreenVisbile(Title)
    }
    return (
        <>
            <Modal
                size="lg"
                show={UpgradeShow}
                onHide={handleUpgradeClose}
                aria-labelledby="example-modal-sizes-title-lg"
                className="modal-size-craft upgrade"
                style={{paddingLeft:"0px"}}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Trading View
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="success-icon">
                    <div className='upgrade-content'>
                       {ScreenVisbile==="plans" ?<div>
                        <div style={{ display: "flex", justifyContent: "center", marginBottom: "20px" }}>
                            <div className="auditons-status-btns">
                                <button className="btn btn-secondary">Non-Professional</button>
                                <button className="btn btn-info">Professional</button>
                            </div>
                        </div>
                        <h1 className='h1'>Plans for every level </h1>
                        <h1 className='h1'>of ambition</h1>
                        <div className="toggle-container">
                            <div className='monthly'>Monthly</div>
                            <div class="form-switch">
                                <input class="form-check-input" style={{height:"20px",width:"40px"}} type="checkbox" id="flexSwitchCheckChecked" checked/>
                            </div>
                            <div className='anually'>Annually</div>
                            <div className="discount-info">16% off annually. It's like 60 days free <span role="img" aria-label="smile">😊</span></div>
                    </div>
                    <div>
                    <div className='flex-item-card'>
                        <div className="plan-card b-1">
                            <div className='text-center'>
                            <h4>Essential</h4>
                            <span><span className="price">₹ 995</span><span> / month</span></span>
                            <p className="Anual-price">₹11,940 / year</p>
                            <p className="description">You save ₹3,600 a year</p>
                            <button className="btn btn-shortlist shortlist-icon" onClick={()=>hnadlescreen("Essential","995","11,940","3,600","1,295")}><p ><b>Buy Now</b></p></button>
                            </div>
                            <ul className='features-list'>
                                <li>4 charts per tab</li>
                                <div className='statusIndicator'>
                                  <div style={{background:"#000",height:"100%", width:"20%"}}>

                                  </div>
                                </div>
                                <li>10 indicators per chart</li>
                                <div className='statusIndicator'>
                                  <div style={{background:"#000",height:"100%", width:"15%"}}>

                                  </div>
                                </div>
                            </ul>
                        </div>
                        <div className="plan-card">
                        <div className='text-center'>
                            <h4>Plus</h4>
                            <span>
                            <span className="price">₹ 1,995</span>
                            <span> / month</span></span>
                            <p className="Anual-price">₹ 23,940 / year</p>
                            <p className="description">You save ₹6,000 a year</p>
                            <button className="btn btn-shortlist shortlist-icon" onClick={()=>hnadlescreen("Plus","1,995","23,940","6,000","1,295")}><p ><b>Buy Now</b></p></button>
                            </div>
                            <ul className='features-list'>
                                <li>4 charts per tab</li>
                                <div className='statusIndicator'>
                                  <div style={{background:"#000",height:"100%", width:"30%"}}>

                                  </div>
                                </div>
                                <li>10 indicators per chart</li>
                                <div className='statusIndicator'>
                                  <div style={{background:"#000",height:"100%", width:"25%"}}>

                                  </div>
                                </div>
                            </ul>
                        </div>
                        <div className="plan-card b-r">
                            <div className='text-center'>
                            <h4>Premium</h4>
                            <span><span className="price">₹ 3,995</span><span> / month</span></span>
                            <p className="Anual-price">₹47,940 / year</p>
                            <p className="description">You save ₹12,000 a year</p>
                            <button className="btn btn-shortlist shortlist-icon" onClick={()=>hnadlescreen("Premium","3,995","47,940","12,000","1,295")}><p ><b>Buy Now</b></p></button>
                            </div>
                            <ul className='features-list'>
                                <li>8 charts per tab</li>
                                <div className='statusIndicator'>
                                  <div style={{background:"#000",height:"100%", width:"45%"}}>

                                  </div>
                                </div>
                                <li>25 indicators per chart</li>
                                <div className='statusIndicator'>
                                  <div style={{background:"#000",height:"100%", width:"45%"}}>

                                  </div>
                                </div>
                            </ul>
                        </div>
                    </div>
                    </div>
                    </div>:<Plans setScreenVisbile={setScreenVisbile} BuyNowData={BuyNowData} setbuyNowData={setbuyNowData}/>}
                   
                </div>
            </Modal.Body>
        </Modal >
        </>
    )
}

export { UpgradePage }
