import React from 'react';
import { MdOutlineArrowBackIos } from "react-icons/md";
const Plans = ({ setScreenVisbile,BuyNowData,setbuyNowData }) => {
    return (
        <div className="plans-content">
            <div onClick={() => setScreenVisbile("plans")} style={{ cursor: "pointer" }}>
                <p><MdOutlineArrowBackIos size={16} /> Back</p>
            </div>
            <div className='row'>
                <div className='col-md-8'>
                    <h4 style={{ fontWeight: "bold" }}>{BuyNowData.Title} Plan</h4>
                    <div style={{ marginTop: "25px" }}>
                        <div className='flex-item-container'>
                            <div>
                                <div class="form-check">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked />
                                    <label className="form-check-label" for="flexRadioDefault1">
                                        Anually
                                    </label>
                                    <label className="form-check-label discount">
                                        save 23%
                                    </label>
                                </div>
                                <div
                                    className="price-msg">save ₹{BuyNowData.discount}.00 when compared to the monthly plan
                                </div>
                            </div>
                            <div>
                                <div className='price'>
                                    <span><span>₹ {BuyNowData.month_disount}.00</span><span> / month</span></span>
                                </div>
                                <div
                                    className="Anual-price">₹{BuyNowData.year}.00 / year
                                </div>
                            </div>
                        </div>
                        <div className='flex-item-container' style={{ border: "none", marginTop: "15px" }}>
                            <div>
                                <div class="form-check">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                                    <label className="form-check-label" for="flexRadioDefault2">
                                        Monthly
                                    </label>
                                </div>
                                <div
                                    className="price-msg">Most flexible Option Billed every month
                                </div>
                            </div>
                            <div>
                                <div className='price'>
                                    <span><span>₹ {BuyNowData.month}.00</span><span> / month</span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div class="card-buy">
                       <div className='flex-item'>
                        <h6>{BuyNowData.Title} Plan</h6>
                        <p>₹ {BuyNowData.month}.00</p>
                       </div>
                       <div className='flex-item' style={{border:"none",marginTop:"10px",alignItems:"normal",marginBottom:"25px"}}>
                        <div>
                         <div>Total</div>
                         <div style={{fontSize:"12px",fontWeight:"400",color:"gray"}}>Billed every month</div>
                        </div>
                        <div style={{fontSize:"16px",fontWeight:"700"}}>
                        ₹ {BuyNowData.month}.00
                        </div>
                       </div>
                       <button className="btn btn-shortlist shortlist-icon"><p ><b>Buy Now</b></p></button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Plans;
