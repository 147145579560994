import React, { useEffect, useState } from "react";
import axiosConfig from "../Service/axiosConfig"
import { Footer, Navbar, PopupModal } from "../Components";
import 'bootstrap/dist/css/bootstrap.min.css';
import { FormParser } from '../Components/FormParser/FormParser';
import Masters from "../Components/masters.json"
import { AuditionRequirements } from "./AuditionRequirements";
import { Modal, Tab, Tabs } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import CustomTop from "./CustomTop";
const AuditionModal = ({ }) => {
    let { id } = useParams()
    console.log(id)
    const [key, setKey] = useState('Audition Info');
    const [popupType, setpopupType] = useState("");
    const [Error, SetError] = useState("");
    const [loading, setLoading] = useState(false);
    const profile_data = JSON.parse(localStorage.getItem('profile_data'));
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    const [AudiData, setAudiData] = useState({
        "banner_name": "",
        "project_type": "",
        "director_name": "",
        "director_mobile_no": "",
        "producer_name": "",
        "producer_mobile_no": "",
        "contact_name": profile_data?.first_name,
        "contact_designation": "",
        "contact_mobile_no": profile_data?.mobile_number,
        "music_director": "",
        "hero": "",
        "platform": "",
        "zoner": "",
        "budget": 0,
        "start_date": formattedDate,
        "end_date": "",
        "state": "",
        "city": "",
        "languages": ""
    });
    const [AudiId, SetAudiId] = useState(null);
    const formSubmit = (Data) => {
        SetError("");
        console.log(AudiData, "dsbxhdhc", Data)
        if (AudiData.id) {
            axiosConfig.patch(`/accounts/auditions/${id}/`, Data).then(res => {
                console.log(res);
                setpopupType("success");
            }).catch(handleError);
        } else {
            axiosConfig.post("/accounts/auditions/", Data).then(res => {
                console.log(res);
                setpopupType("success");
                SetAudiId(res.data.id);
            }).catch(handleError);
        }
    };
    const handleError = (error) => {
        console.log(error.response.data);
        if (error.response.data.contact_mobile_no.includes("Invalid Number")) {
            SetError("Contact person Mobile Number Consists of ten digits");
        }
        if (error.response.data.director_mobile_no.includes("Invalid Number")) {
            SetError("Director Mobile Number Consists of ten digits");
        }
        if (error.response.data.producer_mobile_no.includes("Invalid Number")) {
            SetError("Producer Mobile Number Consists of ten digits");
        }
        setpopupType("error");
    };
    const GetData = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`/accounts/auditions/${id}/`)
            console.log(responce)
            setAudiData(responce.data);
            setLoading(false);
        }
        catch (error) {
            console.log(error)
            setLoading(false);
        }
    }
    useEffect(() => {
        if (id) {
            GetData();
        }
    }, [id])
    return (
        <>
            <CustomTop />
            <div className="search-screen-container">
                <Navbar />
                <div className="content-area audi-modal-content">
                    <div>
                        {/* <div className="client-options">
                            <BiArrowBack onClick={() => handleNavigate("", "back")} className="option-icon" />
                        </div> */}
                        {id &&
                            <div className="client-info">
                                <div className="contact-detile">
                                    Banner Name : <div className="data-text">{AudiData.banner_name}</div>
                                </div>
                                <div className="contact-detile">
                                    Contact Name : <div className="data-text">{AudiData.contact_name}</div>
                                </div>
                                <div className="contact-detile">
                                    Contact Designation Name : <div className="data-text">{AudiData.contact_designation}</div>
                                </div>
                                <div className="contact-detile">
                                    Contact Mobile No : <a href={`tel:+${AudiData.contact_mobile_no}`} className="data-text">{AudiData.contact_mobile_no}</a>
                                </div>
                            </div>
                        }
                    </div>
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                    >
                        <Tab eventKey="Audition Info" title="Audition Info">
                            <FormParser
                                modelObject={Masters.Audition}
                                formSubmit={formSubmit}
                                error={Error}
                                buttonTitle={id ? "Update & Add Requirements" : "Save & Add Requirements"}
                                formData={AudiData} />
                        </Tab>
                        {id &&
                            <Tab eventKey="Requirements" title="Requirements">
                                <>
                                    <AuditionRequirements />
                                </>
                            </Tab>
                        }
                    </Tabs>
                </div>
            </div>
            <Footer />
            {popupType != "" &&
                <PopupModal popupType={popupType} setpopupType={setpopupType} error={Error} Navigation={AudiId ? `/auditions/${AudiId}/edit` : ""} />
            }
        </>
    )
}
export { AuditionModal };