import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { MdMoreVert, MdOutlineEdit, MdOutlineMoreVert, MdOutlineRemoveRedEye, MdOutlineTouchApp, MdOutlineWatchLater } from "react-icons/md";
const AuditionContent = ({ AudiData }) => {
    const Navigate = useNavigate();
    const handleNavigate = (id, type) => {
        console.log(type)
        if (type === "view") {
            Navigate(`/auditions/${id}`)
        } else {
            Navigate(`/auditions/${id}/edit`)
        }
    }
    function getProjectType(status) {
        switch (status) {
            case 'Movies':
                return '#f5d8ff';
            case 'OTT':
                return '#c1c1cc';
            case 'TV_Serials':
                return '#ffd8b1';
            case 'Web_Series':
                return '#b3ecff';
            case 'Music_Albums':
                return '#f0e68c';
            case 'Cover_Songs':
                return '#ffcccb';
            case 'Item_Song':
                return '#ff69b4';
            case 'Short_Films':
                return '#b2fab4';
            case 'Ads':
                return '#ffdab9';
            case 'Modelling':
                return '#e0ffff';
            case 'Audition':
                return '#ffb6c1';
            default:
                return '#ffffff';
        }
    }
    function getProjectTypeColor(status) {
        switch (status) {
            case 'Movies':
                return '#8b008b';
            case 'OTT':
                return '#696969';
            case 'TV_Serials':
                return '#8b4513';
            case 'Web_Series':
                return '#4682b4';
            case 'Music_Albums':
                return '#bdb76b';
            case 'Cover_Songs':
                return '#a52a2a';
            case 'Item_Song':
                return '#c71585';
            case 'Short_Films':
                return '#228b22';
            case 'Ads':
                return '#d2691e';
            case 'Modelling':
                return '#20b2aa';
            case 'Audition':
                return '#db7093';
            default:
                return '#ffffff';
        }
    }
    const shareContent = async (share_to, id) => {
        try {
            // let text = `https://hyderabadcastingclub.com/auditions/${id}/`
            // const shareUrl = encodeURIComponent(text);
            let baseUrl = `${window.location.protocol}//${window.location.host}`;
            let shareUrl = `${baseUrl}/auditions/${id}/`;
            console.log(shareUrl);
            if (share_to === "whatsapp") {
                window.open(`https://api.whatsapp.com/send?text=${shareUrl}`, '_blank');
            } else if (share_to === "fb") {
                window.open(`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`, '_blank');
            } else {
                if (navigator.share) {
                    await navigator.share({
                        title: 'Share Post',
                        // text: `https://hyderabadcastingclub.com/auditions/${id}/`,
                        text: `${shareUrl}`
                    });
                }
            }
        } catch (err) {
            console.error('Failed to share content:', err);
        }
    };
    return (
        <>
            {AudiData.map((Audition, index) => (
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12" key={index} >
                    <div className="card card-style" style={{ backgroundColor: getProjectType(Audition.project_type) }}>
                        <div className="card-type card-types" style={{ backgroundColor: getProjectTypeColor(Audition.project_type), position: "relative" }}>{Audition.project_type ? Audition.project_type : "---"}</div>
                        {/* {Audition?.profile?.id === JSON.parse(localStorage.getItem('profile_data'))?.profile_id &&
                            <div onClick={() => handleNavigate(Audition.id, "edit")} className="audi-edit" ><MdOutlineEdit /></div>
                        } */}
                        <div className="dropdown audi-edit">
                            <MdOutlineMoreVert data-bs-toggle="dropdown" />
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" href="#">Report</a></li>
                                {Audition?.profile?.id === JSON.parse(localStorage.getItem('profile_data'))?.profile_id &&
                                    <>
                                        <li><a className="dropdown-item" onClick={() => handleNavigate(Audition.id, "edit")}>edit</a></li>
                                        <li><a className="dropdown-item" href="#">Delete</a></li>
                                    </>
                                }
                            </ul>
                        </div>
                        <div className='card-content'>
                            {Audition.banner_name ? (
                                <h4 className="banner-title" style={{ textAlign: "center" }}>
                                    {Audition.banner_name}
                                </h4>
                            ) : (
                                <div style={{ padding: '20px' }}></div>
                            )}

                            <div className="card-descrt">
                                <div onClick={() => handleNavigate(Audition.id, "view")} className="card-descrt-inner">
                                    <div style={{ color: "#000" }} className="project_title">
                                        <div className="audi-id">AD{Audition.id && Audition.id}</div>
                                        <div>
                                            <div className='Audi-project-title'>{Audition.project_title && Audition.project_title}</div>
                                            <div className='Audi-description'>{Audition.description && Audition.description}</div>
                                        </div>
                                    </div>
                                    <div className="card-audi">
                                        {Audition.platform &&
                                            <div className="audi-label" style={{ background: '#b00de9' }}>{Audition.platform}</div>
                                        }
                                        {Audition.zoner &&
                                            <div className="audi-label" style={{ background: '#671640' }}>{Audition.zoner}</div>
                                        }
                                        {Audition.loc_lang.state &&
                                            <div className="audi-label" style={{ background: '#8b008b' }}>{Audition.loc_lang.state}</div>
                                        }
                                        {Audition.loc_lang.city &&
                                            <div className="audi-label" style={{ background: '#3cb371' }}>{Audition.loc_lang.city}</div>
                                        }
                                        {Audition.loc_lang.languages &&
                                            <div className="audi-label" style={{ background: '#db7093' }}>{Audition.loc_lang.languages}</div>
                                        }
                                    </div>
                                    <div className="d-flex justify-content-between card-detail">
                                        <div style={{ fontWeight: "800", color: "#000", display: "flex", flexDirection: 'column', }}>
                                            <div className="card-cont-title">Producer</div>
                                            <div className='card-cont-detail'>{Audition.producer_name ? Audition.producer_name : "-"}</div>
                                        </div>
                                        <div style={{ fontWeight: "800", color: "#000", display: "flex", flexDirection: 'column', }}>
                                            <div className="card-cont-title">Director</div>
                                            <div className='card-cont-detail'>{Audition.director_name ? Audition.director_name : "-"}</div>
                                        </div>
                                        {Audition.hero && <div style={{ display: "flex", flexDirection: 'column' }}>
                                            <div className="card-cont-title">Hero</div>
                                            <div className='card-cont-detail'>{Audition.hero ? Audition.hero : "-"}</div>
                                        </div>}
                                    </div>
                                    <div className="auditionList">
                                        <div className="card-cont-title">Requirement:</div>
                                        <div className='card-cont-detail'>
                                            {Audition.generated_requirement && Audition.generated_requirement.trim() !== "" ? Audition.generated_requirement : "No requirements available"}
                                        </div>
                                    </div>
                                </div>
                                <div className="card-bottom">
                                    <div className="card-bottom-inner">
                                        <div className="card-bottom-left">
                                            <div className="card-cont-detail" style={{ display: "flex", alignItems: "center" }}><MdOutlineWatchLater size={20} /><span style={{ marginLeft: "2px" }}>{new Date(Audition.end_date).toLocaleDateString('en-GB').split('/').join("-")}</span></div>
                                            <div className="card-cont-detail" style={{ display: "flex", alignItems: "center" }}><MdOutlineRemoveRedEye size={20} /><span style={{ marginLeft: "2px" }}>{Audition.views}</span></div>
                                        </div>
                                        <div className="card-bottom-right">
                                            <button type="button" className="btn btn-primary" onClick={() => handleNavigate(Audition.id, "view")}><MdOutlineTouchApp />Apply</button>
                                            <button type="button" className="btn btn-secondary" onClick={() => shareContent("share", Audition.id)}>Share</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div >
                    </div >
                </div >
            ))}
        </>
    )
}
export { AuditionContent };