import React, { useState, useEffect, useCallback } from "react";
import "../Components/Navbar/Navbar.css"
import axiosConfig from "../Service/axiosConfig"
import { useSelector } from "react-redux";
import { selectCartTotal } from "../redux/Slices/cartSlice";
import { MdOutlineNotifications, MdOutlineMovieCreation, MdOutlineArrowBack } from "react-icons/md";
import Modal from 'react-bootstrap/Modal';
import { BiSearch, BiAddToQueue, BiFilterAlt } from "react-icons/bi";
import { RiAccountCircleLine } from "react-icons/ri";
import { AiOutlineTeam, AiOutlineStar } from "react-icons/ai";
import { LuMessageSquare } from "react-icons/lu";
import { NavLink, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { AddressModal } from "../Components/PopupModal";
import { PostDP, Navbar } from "../Components";
import { FormParser } from "../Components";
import Masters from "../Components/masters.json"
import { useAuth } from '../Utils/AuthContext';
import { Login, Signup } from "../Components/Login";
import CustomMobile from "./CustomMobile";
import { HiOutlineAdjustmentsHorizontal } from "react-icons/hi2"
import { ExploreGrid } from "./exploregrid";
import { NewPost } from "./NewPost";
import { Notifications } from "./Notifications";
import { useURLParams } from "../Components/UrlUtils/useURLParams"
import { FaTimes } from 'react-icons/fa';
import useDebouncedValue from "../Utils/Debounce";
const SearchProducts = () => {
    const { setParam, getParam, getMultiSelectParam, handleMultiSelect } = useURLParams();
    const { setIsLoggedIn, isLoggedIn, setIs24Craft, is24Craft } = useAuth();
    const [searchParams, setSearchParams] = useSearchParams()
    const Navigate = useNavigate();
    const total = useSelector(selectCartTotal);
    const [loginShow, setloginShow] = useState(false);
    const [loginView, setloginView] = useState("login");
    const handleLoginClose = () => setloginShow(false);
    const handleLoginShow = () => setloginShow(true);
    const [popupType, setPopupType] = useState("")
    const [Data, setData] = useState([])
    const [loading, setLoading] = useState("")
    const [loading2, setLoading2] = useState("")
    const[query,setQuery]=useState(getParam("query")||"")
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1020);
    const [parmsData, setparmsData] = useState("")
    const [ProfileData, setProfileData] = useState({})
    const [filterShow, setFilterShow] = useState(false)
    const [totalPages, settotalPages] = useState()
    const [initialformData, setinitialFormData] = useState({});
    const [isRead, setIsRead] = useState(false);
    const [pages, setPages] = useState(1);
    const [show, setShow] = useState(false);
    const [AddPost, setAddPost] = useState(false)
    const [formData, setformData] = useState({
        department: getParam('department') || "",
        project_type: getParam('project_type') || "",
        role: getMultiSelectParam('role'),
        categories: getParam('categories') || "",
        skills: getParam('skills') || "",
        present_state: getParam('present_state') || "",
        present_city: getParam('present_city') || "",
        languages: getMultiSelectParam('languages'),
        gender: getParam('gender') || "",
        from_age: getParam('from_age') || "",
        to_age: getParam('to_age') || "",
        from_height: getParam('from_height') || "",
        to_height: getParam('to_height') || "",
    })
    const handleClose = () => { setShow(false); setPages(1) }
    const handleShow = () => { setShow(true); setPages(1) }
    const setProfile = () => {
        setProfileData(JSON.parse(localStorage.getItem("profile_data")))
    }
    const formSubmit = (Data) => {
        setFilterShow(false)
        const newData = { ...Data, query: query };
        setformData(Data)
        let queryStringParts = [];
        Object.keys(newData).forEach(key => {
            const value = newData[key];
            if (Array.isArray(value) && value.length > 0) {
                const selectedValues = value.map((item) => item).join(',');
                queryStringParts.push(`${encodeURIComponent(key)}=${encodeURIComponent(selectedValues)}`);
                // value.forEach(arrayValue => {
                //      queryStringParts.push(`${encodeURIComponent(key)}[]=${encodeURIComponent(arrayValue)}`);
                //  });
                // handleMultiSelect(key,value)
            } else if (value && !Array.isArray(value)) {
                queryStringParts.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
            }
        });
        const queryString = queryStringParts.join('&');
        setSearchParams(queryString);
    };


    function handleLoginEvent() {
        handleLoginClose();
        setIsLoggedIn(true);
        console.log("test")
        if (localStorage.getItem('is_24_craft')) {
            setIs24Craft(true)
        }
    }

    const handleResize = () => {
        setIsMobile(window.innerWidth < 1020);
    };
    let page = 1;
    const debouncedSearchTerm = useDebouncedValue(query, 500);
    const handleInputChangeDebounced = (event) => {
        setQuery(event.target.value)
        const params = new URLSearchParams(window.location.search);

        // Update the 'query' parameter with the new value
        params.set('query', event.target.value);

        // Update the URL with the merged parameters
        setSearchParams(params);
        setLoading(true);
    };
    const handleInfiniteScroll = async () => {
        try {
            if (
                window.innerHeight + document.documentElement.scrollTop + 1 >=
                document.documentElement.scrollHeight
            ) {
                if (page <= totalPages && !loading2) {
                    setLoading2(true);
                    page = page + 1;
                    let url = `/accounts/user-profiles/?page_size=20&page=${page}&department=${formData.department ? formData.department : ""}&project_type=${formData.project_type ? formData.project_type : ""}&role=${formData.role ? formData.role : ""}&categories=${formData.categories ? formData.categories : ""}&skills=${formData.skills ? formData.skills : ""}&present_state=${formData.present_state ? formData.present_state : ""}&present_city=${formData.present_city ? formData.present_city : ""}&languages=${formData.languages ? formData.languages : ""}&gender=${formData.gender ? formData.gender : ""}&from_age=${formData.from_age ? formData.from_age : ""}&to_age=${formData.to_age ? formData.to_age : ""}&from_height=${formData.from_height ? formData.from_height : ""}&to_height=${formData.to_height ? formData.to_height : ""}`;
                    if (query !== "") {
                        url += `&search_query=${query}`;
                    }
                    const resp = await axiosConfig.get(url);
                    setData((Data) => [...Data, ...resp.data.results]);
                    setLoading2(false);
                }
            }
        } catch (error) {
            console.log(error);
            setLoading2(false);
        }
    };

    const GetData = async () => {
        try {
            axiosConfig.get(`/accounts/user-profiles/?search_query=${debouncedSearchTerm}&page_size=20&page=${page}&department=${formData.department ? formData.department : ""}&project_type=${formData.project_type ? formData.project_type : ""}&role=${formData.role ? formData.role : ""}&categories=${formData.categories ? formData.categories : ""}&skills=${formData.skills ? formData.skills : ""}&present_state=${formData.present_state ? formData.present_state : ""}&present_city=${formData.present_city ? formData.present_city : ""}&languages=${formData.languages ? formData.languages : ""}&gender=${formData.gender ? formData.gender : ""}&from_age=${formData.from_age ? formData.from_age : ""}&to_age=${formData.to_age ? formData.to_age : ""}&from_height=${formData.from_height ? formData.from_height : ""}&to_height=${formData.to_height ? formData.to_height : ""}`)
                .then(response => {
                    settotalPages(Math.ceil(response.data.count / 20));
                    setData(response.data.results);
                    setLoading(false);
                })
                .catch(error => {
                    console.log(error.response.data.error);
                });
        }
        catch (error) {
            console.log(error)
        }
    }

    const ActiveData = (activeUrl) => {
        setIsLoggedIn(false);
        localStorage.setItem("activeurl", activeUrl)
    }

    const loadFormDataFromURL = () => {
        setLoading(true);
        setinitialFormData(formData);
        setLoading(false);

    };

    useEffect(() => {
        GetData();
        setProfile();
        loadFormDataFromURL();
    }, [formData,debouncedSearchTerm])
    useEffect(() => {
        window.addEventListener('scroll', handleInfiniteScroll);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('scroll', handleInfiniteScroll);
            window.removeEventListener("resize", handleResize);
        }
    }, [totalPages])
    const handleRemove = (key, itemToRemove = null) => {
        setformData((prevFormData) => {
          const newFormData = { ...prevFormData };
    
          if (Array.isArray(newFormData[key])) {
            newFormData[key] = newFormData[key].filter((item) => item !== itemToRemove);
          }
          else if(key==="query"){
            setQuery("")
          }
           else {
            newFormData[key] = '';
          }
          formSubmit(newFormData)
          return newFormData;
        });
      };
    return (
        <>
            <div className="desktop-container">
                <div className="top-main">
                    <div className="logo" onClick={() => Navigate('/')}>
                        <img src={require('../Pages/images/HCC (10).png')} className="logo-image" />
                    </div>
                    <div className="search">
                        {/* <BiFilterAlt className="option-icon filter-icons hide-for-desktop" onClick={() => setFilterShow(true)} /> */}
                        <input
                            type="text"
                            placeholder="Search for Artists..."
                            value={query}
                            onChange={handleInputChangeDebounced}
                            autoFocus
                        />
                        <BiSearch className="option-icon" />
                    </div>
                    {isLoggedIn ?
                        <div className="options">
                            <div className="options-container">
                                <div onClick={() => Navigate('/')}><LuMessageSquare className="option-icon" /></div>
                                <div onClick={handleShow}><MdOutlineNotifications className="option-icon" /></div>
                                {is24Craft &&
                                    <>
                                        <div onClick={(e) => { setAddPost(true); }}><BiAddToQueue className="option-icon" /></div>
                                    </>
                                }
                                <div className="nav-item dropdown account-topnav">
                                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <img src={ProfileData && ProfileData.profile_pic} width="40" className="profile-pic-top" />
                                    </a>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <li>
                                            <a className="dropdown-item">
                                                <NavLink to="/my-profile" >My Account</NavLink>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item">
                                                <NavLink to="/my-profile" >Change Password</NavLink>
                                            </a>
                                        </li>
                                        <li><hr className="dropdown-divider" /></li>
                                        <li><NavLink to="/logout" className="dropdown-item" onClick={() => ActiveData("/logout")}>Log Out</NavLink></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="options">
                            <div className="login-text" onClick={handleLoginShow}>login</div>
                        </div>
                    }
                </div>
            </div>
            <div className="top-mobile-main">
                <div className="search-bar-filter">
                    <MdOutlineArrowBack className="option-icon" onClick={() => Navigate('/')} />
                    <div className="search-mobile" style={{ height: '30px', alignItems: 'center' }}>
                        <input
                            type="text"
                            placeholder="Search for Artists..."
                            value={query}
                            onChange={handleInputChangeDebounced}
                            autoFocus
                        />
                        <BiSearch size={20} />
                    </div>
                    <HiOutlineAdjustmentsHorizontal className="option-icon" onClick={() => setFilterShow(true)} />
                </div>
                <CustomMobile />
            </div>
            <div className="search-screen-container">
                <Navbar />
                {/* <div className="filters-container hide-for-mobile">
                    <FormParser modelObject={Masters.FilterForm} formData={initialformData} formSubmit={formSubmit} />
                </div> */}
                <div className="content-area">
                    <div className="container">
                        <div class="inlineContainer">
                            <div class="inlineHeader">
                                <button className="btn btn-shortlist shortlist-icon" onClick={() => setFilterShow(true)}><p ><b>Filter By</b></p></button>
                            </div><div className="inlinBpContainer">
                                {Object.entries(formData).map(([key, value], index) =>
                                    Array.isArray(value) && value.length > 0 ? (
                                        value.map((item, itemIndex) => (
                                            <button key={`${index}-${itemIndex}`} className="btn btn-shortlist shortlist-icon">
                                                <p style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: 0 }}>
                                                    <b>{`${key}: ${item}`}</b>
                                                    <span style={{ marginLeft: '8px', cursor: 'pointer' }}>
                                                        <FaTimes size={18}  onClick={() => handleRemove(key, item)}/>
                                                    </span>
                                                </p>
                                            </button>
                                        ))
                                    ) : !Array.isArray(value) && value ? (
                                        <button key={index} className="btn btn-shortlist shortlist-icon">
                                           <p style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: 0 }}>
                                                    <b>{`${key}: ${value}`}</b>
                                                    <span style={{ marginLeft: '8px', cursor: 'pointer' }}>
                                                        <FaTimes size={18}  onClick={() => handleRemove(key)}/>
                                                    </span>
                                                </p>
                                        </button>
                                    ) : null
                                )}


                            </div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <ExploreGrid Data={Data} loading={loading} loading2={loading2} />
                </div>
            </div>

            <Modal
                show={filterShow}
                onHide={() => setFilterShow(false)}
                aria-labelledby="example-modal-sizes-title-sm"
                className="mobile-filter-pop modal-responsive"
            >
                <Modal.Body className="">
                    <button variant="primary" className="btn btn-default close-btn" onClick={() => setFilterShow(false)}>
                        X
                    </button>
                    <FormParser modelObject={Masters.FilterForm} formData={initialformData} formSubmit={formSubmit} />
                </Modal.Body>
            </Modal>

            <Modal size={loginView == "login" ? "md" : "lg"} aria-labelledby="contained-modal-title-vcenter" centered show={loginShow} onHide={handleLoginClose} className="login-popup">
                <Modal.Header closeButton>
                    <Modal.Title>{loginView == "login" ? "Login" : "Signup"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loginView == "login" ? (
                        <>
                            <Login handleLoginEvent={handleLoginEvent} />
                            <a href="#" className="switchtag" onClick={() => setloginView("signup")} >Don't have an account ? Click to signup</a>
                        </>
                    ) : (
                        <>
                            <Signup handleLoginEvent={handleLoginEvent} />
                            <a href="#" className="switchtag" onClick={() => setloginView("login")} >Already have an account ? Click to Login</a>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer className="loginfooter">
                    <p>By continuing, you agree to our</p>
                    <a href="#" target="_blank">Terms of service</a>
                    <a href="#" target="_blank">Privacy policy</a>
                </Modal.Footer>
            </Modal>
            {popupType === "success" &&
                <AddressModal popupType={popupType} setPopupType={setPopupType} />
            }
            {AddPost &&
                <NewPost AddPost={AddPost} setAddPost={setAddPost} />
            }
            <Notifications show={show} setShow={setShow} handleClose={handleClose} ProfileData={ProfileData} isRead={isRead} setIsRead={setIsRead} setPage={setPages} page={pages} />
        </>
    )
}
export { SearchProducts };