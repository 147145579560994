import React, { useState, useEffect } from "react";
import { Navbar, Footer, Stories, Follow } from "../Components";
import { useNavigate, useParams } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PostsPageGrid } from "./PostsPageGrid";
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig";
import Modal from 'react-bootstrap/Modal';
import { EditProfile, EditDp, DisplayBio, MyProjects } from "../Components";
import CustomTop from "./CustomTop";
import { MdOutlineModeEditOutline, MdOutlineMoreVert } from "react-icons/md";
import { Shortlist } from "../Components/ShortList/ShortList";
import { FollowersPop, FollowingPop } from "../Components/Follow";
import { BiSolidBadgeCheck } from "react-icons/bi";
import { ShortlistPops } from "../Components/ShortList";
import { useAuth } from '../Utils/AuthContext';
import { CraftsModal } from "./CraftsModal";
import { UpgradePage } from "./UpgradePage";

const ProfilePage = () => {
    const { isLoggedIn, is24Craft } = useAuth();
    let { hcc_id } = useParams();
    let can_edit_profile = false
    if (!hcc_id) {
        hcc_id = localStorage.getItem("hcc_id")
    }
    if (hcc_id === localStorage.getItem("hcc_id")) {
        can_edit_profile = true
    }
    const [EditProfileFor, setEditProfileFor] = useState("profile");
    const [EditProfileShow, setEditProfileShow] = useState(false);
    const [Follower, setFollower] = useState(false);
    const [shortlist, setshortlist] = useState(false);
    const [Following, setFollowing] = useState(false);
    const handleEditProfileClose = () => setEditProfileShow(false);
    const [loading, setLoading] = useState(false)
    const [ProfileData, SetProfileData] = useState({})
    const [showApply, setshowApply] = useState(false)
    const [profilePic, setProfilepic] = useState(false)
    const [UpgradeShow, setUpgradeShow] = useState(false)
    const [ScreenVisbile, setScreenVisbile] = useState("plans")
    function handleLoginEvent() {
        setEditProfileShow(false);
        GetData()
    }
    const GetData = async () => {
        setLoading(true)
        if (hcc_id) {
            try {
                const responce = await axiosConfig.get(`/accounts/user-profiles/?hcc_id=${hcc_id}`)
                console.log(responce)
                SetProfileData(responce.data.results[0])
                if (hcc_id !== localStorage.getItem("hcc_id")) {
                    ProfileView(responce.data.results[0].id)
                }
                setLoading(false)
            }
            catch (error) {
                console.log(error)
                setLoading(false)
            }
        } else {
            let profile_data = JSON.parse(localStorage.getItem("profile_data"))
            console.log("getting and setting profile data")
            try {
                const responce = await axiosConfig.get(`/accounts/user-profiles-all/${profile_data.profile_id}/`)
                console.log(responce)
                SetProfileData(responce.data)
                if (hcc_id !== localStorage.getItem("hcc_id")) {
                    ProfileView(responce.data.id)
                }
                setLoading(false)
            }
            catch (error) {
                console.log(error)
                setLoading(false)
            }
        }
    }
    const [activeTab, setActiveTab] = useState(0);
    const handleTabClick = (index) => {
        setActiveTab(index);
    };
    const handleprofilpicclose = () => {
        setProfilepic(false)
        GetData()
    }
    useEffect(() => {
        GetData();
        let profile_data = JSON.parse(localStorage.getItem("profile_data"))
        if (profile_data?.profile_pic === null) {
            setProfilepic(true)
        }
    }, [])
    const copyToClipboard = async (copy_item) => {
        if (copy_item === "profile") {
            try {
                console.log(ProfileData);
                await navigator.clipboard.writeText(`${ProfileData.user.first_name} - ${ProfileData.designation} - ${calculateAge(ProfileData.dob)} years  https://api.hyderabadcastingclub.com/share/profile/${ProfileData.hcc_id}/`);
                console.log('Text copied to clipboard!');
            } catch (err) {
                console.error('Failed to copy text to clipboard:', err);
            }
        }
    };
    const shareContent = async (share_to) => {
        try {
            let text = `https://api.hyderabadcastingclub.com/share/profile/${ProfileData.hcc_id}/`
            const shareUrl = encodeURIComponent(text);

            if (share_to === "whatsapp") {
                window.open(`https://api.whatsapp.com/send?text=${shareUrl}`, '_blank');
            } else if (share_to === "fb") {
                window.open(`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`, '_blank');
            } else {
                if (navigator.share) {
                    await navigator.share({
                        title: 'Share Post',
                        text: `https://api.hyderabadcastingclub.com/share/profile/${ProfileData.hcc_id}/`,
                    });
                }
            }
        } catch (err) {
            console.error('Failed to share content:', err);
        }
    };
    const calculateAge = (dob) => {
        const dobDate = new Date(dob);
        const today = new Date();
        let age = today.getFullYear() - dobDate.getFullYear();
        const monthDiff = today.getMonth() - dobDate.getMonth();
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dobDate.getDate())) {
            age--;
        }
        return age;
    };
    const navigate = useNavigate();
    const handleNavigation = () => {
        navigate('/request-verifications');
    };
    const ProfileView = async (id) => {
        try {
            const responce = await axiosConfig.get(`/accounts/user-profiles/${id}/viewed/`)
            console.log(responce)
        }
        catch (error) {
            console.log(error);
        }
    }
    const handleShow = () => {
        const profile_data = JSON.parse(localStorage.getItem("profile_data"))
        if (profile_data && profile_data.profile_pic === null || "") {
            setProfilepic(true)
        } else {
            setshowApply(true)
        }
    }
    const handleClose = () => {
        setshowApply(false)
        GetData()
    }
    const HandleUpgrade = () => {
        setUpgradeShow(true)
    }
    const handleUpgradeClose = () => {
        setUpgradeShow(false)
        setScreenVisbile("plans")
    }
    return (
        <>
            <CustomTop />
            <div className="main-container">
                <Navbar />
                <div className="content-area">
                    {loading ? (
                        <div className="loader-container">
                            <img src={require('./images/loader.gif')} alt="Loading..." />
                        </div>
                    ) : (
                        <div>

                            <div className="profile-header">
                                <div className="row">
                                    <div className="col-3">
                                        {can_edit_profile ?
                                            <div className="editabledp">
                                                <img src={ProfileData.profile_pic ? ProfileData.profile_pic : require('./images/dummy_profile.jpg')} className="dp" />
                                                <div class="middle">
                                                    <div class="text" onClick={() => {
                                                        setEditProfileShow(true);
                                                        setEditProfileFor("dp");
                                                    }}>
                                                        <MdOutlineModeEditOutline size={30} />
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <img src={ProfileData.profile_pic ? ProfileData.profile_pic : require('./images/dummy_profile.jpg')} className="dp" />
                                        }
                                    </div>
                                    <div className="col-9">
                                        <div className="profile-details">
                                            <div className="profile-info">
                                                <div className="model-titles">
                                                    <p><b>{ProfileData.full_name}</b>{ProfileData.is_verified && <BiSolidBadgeCheck size={25} />}</p>
                                                    {can_edit_profile ? (
                                                        <>
                                                            <button class="btn btn-default" href="#" onClick={() => {
                                                                setEditProfileShow(true);
                                                                setEditProfileFor("profile");
                                                            }}><MdOutlineModeEditOutline /></button>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Follow FollowId={ProfileData.id} />
                                                            <Shortlist ShortlistId={ProfileData.id} />
                                                        </>
                                                    )}
                                                    <div className="dropdown">
                                                        <MdOutlineMoreVert className="more-icon" data-bs-toggle="dropdown" />
                                                        <ul className="dropdown-menu">
                                                            <li><a className="dropdown-item" onClick={() => copyToClipboard("profile")} href="#!">Copy Profile</a></li>
                                                            <li><a className="dropdown-item" onClick={() => shareContent("share")} href="#">Share</a></li>
                                                            {!can_edit_profile &&
                                                                <>
                                                                    <li><a className="dropdown-item" href="#">Report</a></li>
                                                                    <li><a className="dropdown-item" href="#">Block</a></li>
                                                                </>}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* {!ProfileData.is_verified && can_edit_profile && is24Craft &&
                                                <div>
                                                    <button className="btn btn-default btn-extraborder"
                                                        onClick={HandleUpgrade}
                                                    >Upgrade Now</button>
                                                </div>} */}
                                            {/* {!ProfileData.is_verified && can_edit_profile && is24Craft && <div><button className="btn btn-default btn-extraborder" onClick={handleNavigation}><BiSolidBadgeCheck size={25} /> Get Verified Now</button></div>} */}
                                            <div className="about-profile">
                                                <p>
                                                    <b> {ProfileData.hcc_id} : </b>{ProfileData.designation} {can_edit_profile && <button class="btn btn-default" onClick={handleShow}><MdOutlineModeEditOutline /></button>}
                                                </p>
                                                <p><b>D.O.B : </b>{ProfileData.dob && <>
                                                    {ProfileData.dob} ({calculateAge(ProfileData.dob)} Y) </>}</p>
                                            </div>

                                            <div className="followers-info">
                                                <p className="follow-post"><b>{ProfileData.total_posts}</b> Posts</p>
                                                <p className="follow-post" onClick={() => setFollower(true)}><b>{ProfileData.followers ? ProfileData.followers : 0}</b> Followers</p>
                                                <p className="follow-post" onClick={() => setshortlist(true)}><b>{ProfileData.shortlists ? ProfileData.shortlists : 0}</b> Shortlist</p>
                                                <p className="follow-post"><b>{ProfileData.views ? ProfileData.views : 0}</b> Views</p>
                                                {can_edit_profile &&
                                                    <p className="follow-post" onClick={() => setFollowing(true)}><b>{ProfileData.following ? ProfileData.following : 0}</b> Following</p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row  hide-for-desktop">
                                    {!can_edit_profile && <div className=" col-md-12 profile-button">
                                        <Follow FollowId={ProfileData.id} />
                                        <Shortlist ShortlistId={ProfileData.id} />
                                        <button className="btn btn-shortlist shortlist-icon">Message</button>
                                    </div>}
                                    <div className="followers-info" style={{ justifyContent: 'center', paddingTop: '20px' }} >
                                        <p className="follow-post"><b>{ProfileData.total_posts}</b> Posts</p>
                                        <p className="follow-post" onClick={() => setFollower(true)}><b>{ProfileData.followers ? ProfileData.followers : 0}</b> Followers</p>
                                        <p className="follow-post" onClick={() => setshortlist(true)}><b>{ProfileData.shortlists ? ProfileData.shortlists : 0}</b> ShortList</p>
                                        <p className="follow-post"><b>{ProfileData.views ? ProfileData.views : 0}</b> Views</p>
                                    </div>
                                </div>
                            </div>

                            {hcc_id ?
                                <>
                                    <div className="stories-container">
                                        <Stories />
                                    </div>
                                    <div className="profile-tablinks">
                                        <ul className="nav custom-nav-tabs">
                                            <li className="nav-item">
                                                <button
                                                    className={`nav-link ${activeTab === 0 ? 'active' : ''}`}
                                                    onClick={() => handleTabClick(0)}
                                                >
                                                    GALLERY
                                                </button>
                                            </li>
                                            <li className="nav-item">
                                                <button
                                                    className={`nav-link ${activeTab === 1 ? 'active' : ''}`}
                                                    onClick={() => handleTabClick(1)}
                                                >
                                                    BIO DATA
                                                </button>
                                            </li>
                                            <li className="nav-item">
                                                <button
                                                    className={`nav-link ${activeTab === 2 ? 'active' : ''}`}
                                                    onClick={() => handleTabClick(2)}
                                                >
                                                    PROJECTS
                                                </button>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="tab-content">
                                        <div className={`tab-pane fade ${activeTab === 0 ? 'show active' : ''}`}>
                                            <div className="posting-container">
                                                <PostsPageGrid profile_id={ProfileData.id} can_edit_profile={can_edit_profile} />
                                            </div>
                                        </div>
                                        <div className={`tab-pane fade ${activeTab === 1 ? 'show active' : ''}`}>
                                            {activeTab === 1 &&
                                                <div className="display-bio">
                                                    <DisplayBio ProfileData={ProfileData} can_edit_profile={can_edit_profile} />
                                                </div>
                                            }
                                        </div>
                                        <div className={`tab-pane fade ${activeTab === 2 ? 'show active' : ''}`}>
                                            <div className="projects-container">
                                                {activeTab === 2 &&
                                                    <div className="display-projects">
                                                        <MyProjects ProfileData={ProfileData} can_edit_profile={can_edit_profile} />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                </>
                                :
                                <>
                                    <div className="no-profile">
                                        {/* <NavLink to="/24crafts"> */}
                                        <button className="btn btn-default" onClick={handleShow}>Select Your Department</button>
                                        {/* </NavLink> */}
                                    </div>
                                </>
                            }
                            <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={EditProfileShow} onHide={handleEditProfileClose} className="edit-profile-popup">
                                <Modal.Header closeButton>
                                    <Modal.Title>Edit Profile</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <>
                                        {EditProfileFor === "dp" ?
                                            <EditDp ProfileData={ProfileData} handleLoginEvent={handleLoginEvent} />
                                            :
                                            <EditProfile ProfileData={ProfileData} handleLoginEvent={handleLoginEvent} />
                                        }
                                    </>
                                </Modal.Body>
                            </Modal>
                        </div>
                    )}
                </div>
                {Follower === true &&
                    <FollowersPop Follower={Follower} setFollower={setFollower} Following={Following} setFollowing={setFollowing} ProfileData={ProfileData} />
                }
                {Following === true &&
                    <FollowingPop Following={Following} setFollowing={setFollowing} ProfileData={ProfileData} />
                }
                {shortlist === true &&
                    <ShortlistPops shortlist={shortlist} setshortlist={setshortlist} ProfileData={ProfileData} />
                }
                {showApply &&
                    <CraftsModal showApply={showApply} setshowApply={setshowApply} handleClose={handleClose} />
                }
                {UpgradeShow &&
                    <UpgradePage UpgradeShow={UpgradeShow} setUpgradeShow={setUpgradeShow} ScreenVisbile={ScreenVisbile} setScreenVisbile={setScreenVisbile} handleUpgradeClose={handleUpgradeClose} />
                }
                <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={profilePic} onHide={handleprofilpicclose} className="edit-profile-popup">
                    <Modal.Header closeButton>
                        <Modal.Title>Add your profile to get started</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <EditDp handleLoginEvent={handleprofilpicclose} showApply={showApply} setshowApply={setshowApply} />
                    </Modal.Body>
                </Modal>
            </div>
            <Footer />
        </>
    )

}
export { ProfilePage }